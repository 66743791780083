import React, { useEffect, useState } from "react";
import Headbar from "../../../common/headBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from "../../../common/grid";
import Button from "../../../common/button";

// Media Import
import BackImage from "../../../assets/images/icons/backArrow.svg";
import DealerIcons from "../../../assets/images/icons/DealerIcons.svg";
import DealerList from "../../../assets/images/icons/dealerList.svg";
import addresses from "../../../assets/images/Dealer/Address.svg";
import name from "../../../assets/images/Dealer/Name.svg";
import AddItem from "../../../assets/images/icons/addItem.svg";
import DealerActive from "../../../assets/images/icons/dealerDetails.svg";
import ClaimActive from "../../../assets/images/Dealer/Claim-active.svg";
import UserActive from "../../../assets/images/Dealer/User-active.svg";
import Dealer from "../../../assets/images/icons/dealer.svg";
import Claim from "../../../assets/images/Dealer/Claim.svg";
import User from "../../../assets/images/Dealer/Users.svg";
import email from "../../../assets/images/Dealer/Email.svg";
import phone from "../../../assets/images/Dealer/Phone.svg";
import Modal from "../../../common/model";
import Input from "../../../common/input";
import OrderActive from "../../../assets/images/Dealer/Order-active.svg";
import Order from "../../../assets/images/Dealer/Orders.svg";
import Select from "../../../common/select";
import { RotateLoader } from "react-spinners";

import {
  addCustomerAddressById,
  getCustomerDetailsById,
  getUserListByCustomerId,
  updateCustomerDetailsById,
} from "../../../services/customerServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import { cityData } from "../../../stateCityJson";
import {
  addUserToCustomer,
  checkUserToken,
  getUserListByDealerId,
} from "../../../services/userServices";
import RadioButton from "../../../common/radio";
import Primary from "../../.././assets/images/SetPrimary.png";
import { MyContextProvider, useMyContext } from "../../../context/context";
import OrderList from "../Reseller/Dealer-Details/order";
import ContractList from "../Reseller/Dealer-Details/contract";
import UserList from "../../dashboard/Dealer/Dealer-Details/user";
import ClaimList from "../../dashboard/Claim/claimList";
import { getUserDetailsFromLocalStorage } from "../../../services/extraServices";
import SingleView from "../../../common/singleView";
import CustomerSetting from "../Reseller/Dealer-Details/customerSetting";
import InActiveButton from "../../../common/inActiveButton";

function CustomerDetails() {
  const [activeTab, setActiveTab] = useState("Order"); // Set the initial active tab
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstMessage, setFirstMessage] = useState("");
  const [secondMessage, setSecondMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [customerDetail, setCustomerDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [refreshList, setRefreshUserList] = useState([]);
  const [isUserModalOpen1, setIsUserModalOpen1] = useState(false);
  const [createAccountOption, setCreateAccountOption] = useState("yes");
  const [createAccount, setCreateAccount] = useState(false);
  const [createMainAccount, setCreateMainAccount] = useState(false);
  const [timer, setTimer] = useState(3);
  const { customerId } = useParams();
  const [initialFormValues, setInitialFormValues] = useState({
    username: "",
    dealerId: "",
    street: "",
    city: "",
    zip: "",
    state: "",
    country: "USA",
    oldName: "",
    isAccountCreate: createMainAccount,
  });

  const [initialFormValues1, setInitialFormValues1] = useState({
    address: "",
    city: "",
    zip: "",
    state: "",
  });
  const [initialUserFormValues, setInitialUserFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    position: "",
    status: "yes",
    customerId: customerId,
    isPrimary: false,
  });

  const handleSelectChange1 = async (name, selectedValue) => {
    address.setFieldValue(name, selectedValue);
  };
  const { flag } = useMyContext();
  const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const state = cityData;
  console.log(customerId);

  const closeModal = () => {
    setIsModalOpen(false);
    formik.resetForm();
  };
  useEffect(() => {
    setLoading(true);
    let intervalId;

    if (modalOpen && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      closeModal10();
    }

    if (!modalOpen) {
      clearInterval(intervalId);
      setTimer(3);
    }

    setLoading(false);

    return () => {
      clearInterval(intervalId);
    };
  }, [modalOpen, timer]);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal10 = () => {
    setModalOpen(false);
    // setActiveTab("Users");
  };
  const closeUserModal = () => {
    setActiveTab("Users");
    localStorage.setItem("isPopupOpen", "false");
    setIsUserModalOpen(false);

    userValues.resetForm();
  };

  const userValues = useFormik({
    initialValues: initialUserFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      lastName: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      email: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .matches(emailValidationRegex, "Invalid email address")
        .required("Required"),
    }),

    onSubmit: async (values, { setFieldError }) => {
      localStorage.setItem("customer", "Users");
      console.log(values);
      if (values.status === "yes") {
        values.status = true;
      }
      setLoading(true);
      const result = await addUserToCustomer(values);
      console.log(result.code);
      if (result.code == 200) {
        getUserList();
        // dealerData();
        setModalOpen(true);
        setFirstMessage("New User Added Successfully");
        setSecondMessage("New User Added Successfully");
        setMessage("Dealer updated Successfully");
        setLoading(false);
        closeUserModal();
        setTimer(3);
      } else {
        console.log(result);
        console.log("here");
        if (result.code === 401) {
          setFieldError("email", "Email already in use");
        }
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    getUserList();
  }, []);

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    formik.setFieldValue(
      "isAccountCreate",
      selectedValue === "yes" ? true : false
    );
    setCreateAccountOption(selectedValue);
  };

  const handleAccountChange = (event) => {
    const valueAsBoolean = JSON.parse(event.target.value.toLowerCase());
    setCreateAccount(valueAsBoolean);
    userValues.setFieldValue("status", valueAsBoolean);
  };
  const getUserList = async () => {
    const result = await getUserListByCustomerId({}, customerId);
    console.log(result.result, "----------");
    setRefreshUserList(result.result);
  };
  const handleSelectChange = async (name, value) => {
    formik.setFieldValue(name, value);
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      // dealerId: Yup.string().required("Required"),
      username: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      street: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      state: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required"),
      city: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required"),
      country: Yup.string().required("Required"),
      zip: Yup.string()
        .required("Required")
        .min(5, "Must be at least 5 characters")
        .max(6, "Must be exactly 6 characters"),
    }),

    onSubmit: async (values, { setFieldError }) => {
      if (values.status === "yes") {
        values.status = true;
      }
      console.log(values, "-------------valus");
      const result = await updateCustomerDetailsById(customerId, values);
      console.log(result.code);
      if (result.code == 200) {
        customerDetails();
        // dealerData();
        setModalOpen(true);
        setFirstMessage("Customer Updated Successfully");
        setSecondMessage("Customer Updated Successfully");
        setLoading(false);
        closeModal();
        setTimer(3);
      } else {
        console.log(result);
        console.log("here");
        if (result.code === 401) {
          setFieldError("email", "Email already in use");
        }
        setLoading(false);
      }
    },
  });
  const openUserModal = () => {
    setActiveTab("Users123");
    localStorage.setItem("isPopupOpen", "true");
    setIsUserModalOpen(true);
  };

  const closeUserModal1 = () => {
    setIsUserModalOpen1(false);
    setActiveTab("Settings");
    localStorage.setItem("isPopupOpen1", "false");
    address.resetForm();
  };

  const address = useFormik({
    initialValues: initialFormValues1,
    enableReinitialize: true,
    validationSchema: Yup.object({
      address: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      state: Yup.string()
        .required("Required"),
      city: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required"),
      zip: Yup.string()
        .required("Required")
        .min(5, "Must be at least 5 characters")
        .max(6, "Must be exactly 6 characters"),
    }),

    onSubmit: async (values) => {
      localStorage.setItem("customer", "Settings");

      setLoading(true);
      try {
        const address = {
          address: values,
        }
        const result = await addCustomerAddressById(customerId, address);
        console.log(result);
        if (result.code == 200) {
          customerDetails();
          setModalOpen(true);
          setFirstMessage(" New Address Added Successfully");
          setSecondMessage("New Address Added Successfully");
          setMessage("Address Added Successfully");
          setLoading(false);
          closeUserModal1();
          setActiveTab("Settings");
        } else {
          setLoading(false);
          formik.setFieldError("address", "Address Already Added");
        }
      } catch (error) {
        console.error("Error adding address:", error);
      } finally {
        localStorage.setItem("customer", "Settings");
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const isPopupOpen = localStorage.getItem("isPopupOpen") === "true";
    if (isPopupOpen) {
      setActiveTab("Users");
    }
  }, []);

  useEffect(() => {
    customerDetails();
  }, [customerId, flag]);

  const routeToPage = (data) => {
    console.log(
      `/dealer/addOrderforCustomer/${customerId}/${customerDetail?.meta?.resellerId}`
    );
    switch (data) {
      case "Order":
        const resellerIdParam = customerDetail?.meta?.resellerId
          ? `/${customerDetail?.meta?.resellerId}`
          : "";
        navigate(`/dealer/addOrderforCustomer/${customerId}${resellerIdParam}`);
        break;
      case "Users":
        openUserModal();
        break;
      case "Claims":
        localStorage.setItem("menu", "Claims");
        navigate(`/dealer/addClaim/${customerDetail?.meta?.username}`);
        break;
      case "Settings":
        openUserModal1();
        break;
      default:
        console.log("Invalid data, no navigation");
    }
  };

  const openUserModal1 = () => {
    setActiveTab("Settings123");
    localStorage.setItem("isPopupOpen1", "true");
    setIsUserModalOpen1(true);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups of 3 digits
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber; // Return original phone number if it couldn't be formatted
  };

  const customerDetails = async () => {
    setLoading(true);
    console.log(customerId);
    const result = await getCustomerDetailsById(customerId);
    if (result.code == 200) {
      setCustomerDetail(result.result);
      setCreateMainAccount(result.result.userAccount);
      setCreateAccountOption(
        result.result.primary.status === false ? "no" : "yes"
      );
      setCreateAccount(
        result.result.meta.isAccountCreate && result.result.userAccount
          ? true
          : false
      );

      console.log(result.result.primary.status, "??????????????");
      setInitialFormValues({
        username: result?.result?.meta?.username,
        oldName: result?.result?.meta?.username,
        street: result?.result?.meta?.street,
        city: result?.result?.meta?.city,
        zip: result?.result?.meta?.zip,
        state: result?.result?.meta?.state,
        country: "USA",
      });
    } else {
      navigate(`/`);
    }
    setLoading(false);
  };
  useEffect(() => {
    localStorage.setItem("customer", activeTab);
    checkTokenExpiry();
  }, [activeTab]);

  const checkTokenExpiry = async () => {
    try {
      const response = await checkUserToken();
      console.log(response.code == 200);
      if (response.code == 200) {
        return;
      } else {
        navigate(`/`);
        localStorage.removeItem("userDetails");
      }
    } catch (error) {
      navigate(`/`);
      localStorage.removeItem("userDetails");
    } finally {
    }
  };
  const tabs = [
    {
      id: "Order",
      label: "Order",
      icons: Order,
      Activeicons: OrderActive,
      content: activeTab === "Order" && (
        <OrderList flag={"customer"} id={customerId} activeTab={activeTab} />
      ),
    },
    {
      id: "Contracts",
      label: "Contracts",
      icons: Dealer,
      Activeicons: DealerActive,
      content: activeTab === "Contracts" && (
        <ContractList flag={"customer"} id={customerId} activeTab={activeTab} />
      ),
    },
    {
      id: "Claims",
      label: "Claims",
      icons: Claim,
      Activeicons: ClaimActive,
      content: activeTab === "Claims" && (
        <ClaimList id={customerId} flag={"customer"} activeTab={activeTab} />
      ),
    },
    {
      id: "Users",
      label: "Users",
      icons: User,
      Activeicons: UserActive,
      content: (
        <UserList id={customerId} flag={"customer"} activeTab={activeTab} setLoading={setLoading} />
      ),
    },
    {
      id: "Settings",
      label: "Settings",
      icons: User,
      Activeicons: UserActive,
      content: (
        <CustomerSetting id={customerId} flag={"customer"} activeTab={activeTab} />
      ),
    },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const navigate = useNavigate();
  const handleGOBack = () => {
    localStorage.removeItem("customer");
    navigate(-1);
  };

  const formatOrderValue = (orderValue) => {
    if (Math.abs(orderValue) >= 1e6) {
      return (orderValue / 1e6).toFixed(2) + "M";
    } else {
      return orderValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };
  const [buttonTextColor, setButtonTextColor] = useState('');
  const [backGroundColor, setBackGroundColor] = useState('');

  useEffect(() => {
    const storedUserDetails = getUserDetailsFromLocalStorage();

    if (storedUserDetails) {
      const colorScheme = storedUserDetails.colorScheme;
      colorScheme?.forEach(color => {
        switch (color.colorType) {
          case 'buttonColor':
            setBackGroundColor(color.colorCode);
            break;
          case 'buttonTextColor':
            setButtonTextColor(color.colorCode);
            break;
          default:
            break;
        }
      });
    }
  }, []);

  const InactiveTabButton = ({ tab, onClick }) => (
    <InActiveButton
      className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey "
      onClick={onClick}
    >
      <div
        style={{
          maskImage: `url(${tab.icons})`,
          WebkitMaskImage: `url(${tab.icons})`,
          backgroundColor: backGroundColor,
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskPosition: "center",
          WebkitMaskPosition: "center",
          maskSize: "contain",
          WebkitMaskSize: "contain",
        }}
        className="self-center pr-1 py-1 h-4 w-4"
      />
      <span
        style={{
          borderColor: backGroundColor,
          borderLeftWidth: "1px",
          paddingLeft: "7px",
          color: backGroundColor,
        }}
        className="ml-1 py-1 text-sm font-Regular"
      >
        {tab.label}
      </span>
    </InActiveButton>
  );

  // ActiveTabButton Component
  const ActiveTabButton = ({ tab, onClick }) => (
    <Button
      className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey"
      onClick={onClick}
    >
      <div
        style={{
          maskImage: `url(${tab.Activeicons})`,
          WebkitMaskImage: `url(${tab.Activeicons})`,
          backgroundColor: buttonTextColor,
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskPosition: "center",
          WebkitMaskPosition: "center",
          maskSize: "contain",
          WebkitMaskSize: "contain",
        }}
        className="self-center pr-1 py-1 h-4 w-4"
      />
      <span
        style={{
          borderColor: buttonTextColor,
          borderLeftWidth: "1px",
          paddingLeft: "7px",
          color: buttonTextColor,
        }}
        className="ml-1 py-1 text-sm font-Regular"
      >
        {tab.label}
      </span>
    </Button>
  );
  return (
    <>
      {loading && (
        <div className=" fixed z-[999999] bg-[#333333c7] backdrop-blur-xl  h-screen w-full flex py-5">
          <div className="self-center mx-auto">
            <RotateLoader color="#fff" />
          </div>
        </div>
      )}
      <div className="py-8 pl-3 relative ">
        <Headbar />

        <div className="flex">
          <Link
            to={"/dealer/customerList"}
            className="h-[60px] w-[60px] flex border-[1px] bg-white border-Light-Grey rounded-[25px]"
          >
            <img
              src={BackImage}
              className="m-auto my-auto self-center bg-white"
              alt="BackImage"
            />
          </Link>
          <div className="pl-3">
            <p className="font-bold text-[36px] leading-9 mb-[3px]">
              Customer Details
            </p>
            <ul className="flex self-center">
              <li className="text-sm font-Regular">
                <Link to={"/dealer/dashboard"}>Home / </Link>{" "}
              </li>
              <li className="text-sm font-Regular ml-1">
                <Link to={"/dealer/customerList"}> Customer List / </Link>{" "}
              </li>
              <li className="text-sm font-semibold ml-1 pt-[1px]">
                {" "}
                Customer Details ({activeTab})
              </li>
            </ul>
          </div>
        </div>

        <Grid className="!grid-cols-4 mt-5">
          <div className="col-span-1 max-h-[85vh] overflow-y-scroll">
            <SingleView className=" bg-Dealer-details bg-cover p-5 rounded-[20px]">
              <Grid>
                <div className="col-span-9">
                  <p className="text-sm font-Regular">
                    Account Name
                  </p>
                  <p className="text-xl font-semibold break-words">
                    {customerDetail?.meta?.username}
                  </p>
                </div>
                <div className="col-span-3 text-end">
                  <Button
                    className="border !border-Bright-Grey !text-sm !font-Regular"
                    onClick={openModal}
                  >
                    Edit
                  </Button>
                </div>
              </Grid>
              <div className="flex my-4">
                <img
                  src={addresses}
                  className="mr-3 bg-Onyx rounded-[14px] my-auto"
                  alt="Address"
                />
                <div>
                  <p className="text-sm font-Regular mt-3">
                    Address
                  </p>
                  <p className="text-base font-semibold leading-5">
                    {customerDetail?.meta?.city}
                    {", "}
                    {customerDetail?.meta?.street}
                    {", "}
                    {customerDetail?.meta?.state}
                    {", "}
                    {customerDetail?.meta?.country}
                  </p>
                </div>
              </div>
              <div className="flex w-full my-4">
                <p className="text-[10px] mr-3 font-Regular">
                  PRIMARY CONTACT DETAILS
                </p>
                <hr className="self-center border-[#999999] w-[40%]" />
              </div>
              {customerDetail?.meta?.resellerId && (
                <div className="flex mb-4">
                  <div className="relative">
                    <img
                      src={DealerIcons}
                      className="mr-3 bg-Onyx rounded-[14px]"
                      alt="DealerIcons"
                    />
                    <Link
                      to={`/dealer/resellerDetails/${customerDetail?.meta?.resellerId}`}
                    >
                      {" "}
                      <img
                        src={DealerList}
                        className="mr-3 bg-Onyx cursor-pointer rounded-[14px] absolute top-3 -right-2"
                        alt="DealerList"
                      />{" "}
                    </Link>
                  </div>
                  <div>
                    <p className="text-sm font-Regular">
                      Reseller Name
                    </p>
                    <p className="text-base font-semibold ">
                      {customerDetail?.resellerName}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex mb-4">
                <img
                  src={name}
                  className="mr-3 bg-Onyx rounded-[14px]"
                  alt="Name"
                />
                <div>
                  <p className="text-sm font-Regular">Name</p>
                  <p className="text-base font-semibold ">
                    {customerDetail?.primary?.firstName}{" "}
                    {customerDetail?.primary?.lastName}
                  </p>
                </div>
              </div>
              <div className="flex mb-4">
                <img
                  src={email}
                  className="mr-3 bg-Onyx rounded-[14px]"
                  alt="email"
                />
                <div className="w-[80%]">
                  <p className="text-sm font-Regular">
                    Email
                  </p>
                  <p className="text-base leading-[13px] font-semibold break-words">
                    {customerDetail?.primary?.email}
                  </p>
                </div>
              </div>
              <div className="flex mb-4">
                <img
                  src={phone}
                  className="mr-3 bg-Onyx rounded-[14px]"
                  alt="name"
                />
                <div>
                  <p className="text-sm font-Regular">
                    Phone Number
                  </p>
                  <p className="text-base font-semibold ">
                    +1 {formatPhoneNumber(customerDetail?.primary?.phoneNumber)}
                  </p>
                </div>
              </div>
              <Grid className="mt-5">
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg !font-[600]">
                      {" "}
                      {customerDetail?.orderData?.[0]?.noOfOrders ?? 0}
                    </p>
                    <p className="text-neutral-grey text-sm font-Regular">
                      Total number of Orders
                    </p>
                  </div>
                </div>
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg  !font-[600]">
                      $
                      {formatOrderValue(
                        customerDetail?.orderData?.[0]?.orderAmount ??
                        parseInt(0)
                      )}
                    </p>
                    <p className="text-neutral-grey text-sm font-Regular">
                      Total Value of Orders
                    </p>
                  </div>
                </div>
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg !font-[600]">
                      {customerDetail?.claimData?.numberOfClaims}
                    </p>
                    <p className="text-neutral-grey text-sm font-Regular">
                      Total number of Claims
                    </p>
                  </div>
                </div>
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg  !font-[600]">${formatOrderValue(
                      customerDetail?.claimData?.valueClaim ??
                      parseInt(0)
                    )}</p>
                    <p className="text-neutral-grey text-sm font-Regular">
                      Total Value of Claims
                    </p>
                  </div>
                </div>
              </Grid>
            </SingleView>
          </div>
          <div className="col-span-3 max-h-[85vh] pr-3 overflow-y-scroll">
            <Grid className="">
              <div className="col-span-8">
                <div className="bg-white rounded-[30px] p-3 border-[1px] border-Light-Grey">
                  <Grid className="!grid-cols-5 !gap-1">
                    {tabs.map((tab) =>
                      activeTab === tab.id ? (
                        <ActiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                      ) : (
                        <InactiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                      )
                    )}
                  </Grid>
                </div>
              </div>
              <div className="col-span-2"></div>
              <div className="col-span-2">
                {activeTab !== "Contracts" ? (
                  <InActiveButton className="flex self-center h-full mb-4 rounded-xl ml-auto border-[1px] border-Light-Grey" onClick={() => routeToPage(activeTab)}>
                    <div
                      style={{
                        maskImage: `url(${AddItem})`,
                        WebkitMaskImage: `url(${AddItem})`,
                        backgroundColor: backGroundColor,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="self-center pr-1 h-4 w-4"
                    />
                    <span
                      style={{
                        borderColor: backGroundColor,
                        borderLeftWidth: "1px",
                        paddingLeft: "7px",
                        color: backGroundColor,
                      }}
                      className="text-black ml-1 text-[13px] self-center font-Regular !font-[700]"
                    >
                      Add {activeTab == "Settings" ? 'Address' : activeTab}
                    </span>{" "}
                  </InActiveButton>
                ) : (
                  <></>
                )}
              </div>
            </Grid>

            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`${activeTab !== tab.id ? "hidden" : ""}`}
              >
                {tab.content}
              </div>
            ))}
          </div>
        </Grid>

        <Modal isOpen={isUserModalOpen} onClose={closeUserModal}>
          <div className=" py-3">
            <p className=" text-center text-3xl mb-5 mt-2 font-bold">
              Add New User
            </p>
            <form onSubmit={userValues.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="firstName"
                    label="First Name"
                    required={true}
                    placeholder=""
                    className="!bg-white"
                    maxLength={"30"}
                    value={userValues.values.firstName}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.firstName &&
                      userValues.errors.firstName
                    }
                  />
                  {userValues.touched.firstName &&
                    userValues.errors.firstName && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.firstName}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    placeholder=""
                    className="!bg-white"
                    maxLength={"30"}
                    value={userValues.values.lastName}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.lastName && userValues.errors.lastName
                    }
                  />
                  {userValues.touched.lastName &&
                    userValues.errors.lastName && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.lastName}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="email"
                    label="Email"
                    placeholder=""
                    className="!bg-white"
                    required={true}
                    value={userValues.values.email}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={userValues.touched.email && userValues.errors.email}
                  />
                  {userValues.touched.email && userValues.errors.email && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {userValues.errors.email}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="tel"
                    name="phoneNumber"
                    label="Phone"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    value={userValues.values.phoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      console.log(sanitizedValue);
                      userValues.handleChange({
                        target: {
                          name: "phoneNumber",
                          value: sanitizedValue,
                        },
                      });
                    }}
                    onBlur={userValues.handleBlur}
                    minLength={"10"}
                    maxLength={"10"}
                    error={
                      userValues.touched.phoneNumber &&
                      userValues.errors.phoneNumber
                    }
                  />
                  {(userValues.touched.phoneNumber ||
                    userValues.submitCount > 0) &&
                    userValues.errors.phoneNumber && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.phoneNumber}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="position"
                    label="Position"
                    className="!bg-white"
                    placeholder=""
                    maxLength={"50"}
                    value={userValues.values.position}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.position && userValues.errors.position
                    }
                  />
                </div>
                <div className="col-span-6">
                  <p className="flex text-[12px] font-semibold mt-3 mb-6">
                    Do you want to create an account?
                    <RadioButton
                      id="yes-create-account"
                      label="Yes"
                      value={true}
                      checked={createAccount === true}
                      disabled={
                        !customerDetail?.userAccount ||
                        !customerDetail?.meta?.isAccountCreate
                      }
                      onChange={handleAccountChange}
                    />
                    <RadioButton
                      id="no-create-account"
                      label="No"
                      value={false}
                      checked={createAccount === false}
                      disabled={
                        !customerDetail?.userAccount ||
                        !customerDetail?.meta?.isAccountCreate
                      }
                      onChange={handleAccountChange}
                    />
                  </p>
                </div>
              </Grid>
              <Grid className="px-8 drop-shadow-5xl">
                <div className="col-span-4">
                  <InActiveButton
                    type="button"
                    className="border w-full !text-sm !font-Regular"
                    onClick={closeUserModal}
                  >
                    Cancel
                  </InActiveButton>
                </div>
                <div className="col-span-8">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        </Modal>
        {/* Modal Email Popop */}
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="text-center px-8 py-4">
            <p className="text-3xl font-bold mb-8">Edit Customer Details</p>
            <form className="mt-8" onSubmit={formik.handleSubmit}>
              <Grid>
                <div className="col-span-12">
                  <Input
                    type="text"
                    name="username"
                    className="!bg-white"
                    label="Account Name"
                    required={true}
                    placeholder=""
                    maxLength={"500"}
                    value={formik.values.username}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.username && formik.errors.username}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.username}
                    </div>
                  )}
                </div>
                <div className="col-span-12">
                  <Input
                    type="text"
                    name="street"
                    className="!bg-white"
                    label="Street Address"
                    required={true}
                    placeholder=""
                    maxLength={"500"}
                    value={formik.values.street}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.street && formik.errors.street}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.street}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="number"
                    name="zip"
                    label="Zip Code"
                    className="!bg-white"
                    required={true}
                    placeholder=""
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    minLength={"5"}
                    maxLength={"6"}
                    error={formik.touched.zip && formik.errors.zip}
                  />
                  {formik.touched.zip && formik.errors.zip && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.zip}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="city"
                    label="City"
                    className="!bg-white"
                    placeholder=" "
                    required={true}
                    maxLength={"20"}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && formik.errors.city}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.city}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Select
                    label="State"
                    name="state"
                    placeholder=""
                    className="!bg-white"
                    required={true}
                    onChange={handleSelectChange}
                    options={state}
                    value={formik.values.state}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && formik.errors.state}
                  />
                  {formik.touched.state && formik.errors.state && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.state}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="country"
                    className="!bg-white"
                    label="Country"
                    required={true}
                    placeholder=""
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue="USA"
                    error={formik.touched.country && formik.errors.country}
                    disabled
                  />
                </div>
                <div className="col-span-12">
                  <p className=" flex text-[12px] font-semibold mt-3 mb-6">
                    Do you want to create an account?
                    <RadioButton
                      id="yes-create-account"
                      label="Yes"
                      value="yes"
                      disabled={
                        !customerDetail?.userAccount &&
                        !customerDetail?.meta?.isAccountCreate
                      }
                      checked={createAccountOption === "yes"}
                      onChange={handleRadioChange}
                    />
                    <RadioButton
                      id="no-create-account"
                      label="No"
                      value="no"
                      disabled={
                        !customerDetail?.userAccount &&
                        !customerDetail?.meta?.isAccountCreate
                      }
                      checked={createAccountOption === "no"}
                      onChange={handleRadioChange}
                    />
                  </p>
                </div>
                <div className="col-span-4">
                  <InActiveButton
                    type="button"
                    className="border w-full !text-sm !font-Regular"
                    onClick={closeModal}
                  >
                    Cancel
                  </InActiveButton>
                </div>
                <div className="col-span-8">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        </Modal>
        <Modal isOpen={modalOpen} onClose={closeModal10}>
          <div className="text-center py-3">
            <img src={Primary} alt="email Image" className="mx-auto" />
            <p className="text-3xl mb-0 mt-2 font-bold ">
              {firstMessage}
            </p>
            <p className="text-base font-medium mt-4">
              {secondMessage} {""} <br /> Redirecting Back to Detail page in{" "}
              {timer} Seconds
            </p>
          </div>
        </Modal>

        <Modal isOpen={isUserModalOpen1} onClose={closeUserModal1}>
          <div className=" py-3">
            <p className=" text-center text-3xl mb-5 mt-2 font-bold">
              Add Address
            </p>
            <form onSubmit={address.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-12">
                  <Input
                    type="text"
                    name="address"
                    label="Street Address"
                    className="!bg-white"
                    value={address.values.address}
                    onChange={address.handleChange}
                    onBlur={address.handleBlur}
                    required={true}
                    disabled={loading}
                  />
                  {address.touched.address &&
                    address.errors.address && (
                      <p className="text-red-500 text-xs pl-2">
                        {address.errors.address}
                      </p>
                    )}
                </div>
                <div className="col-span-4">
                  <Input
                    type="text"
                    name="city"
                    label="City"
                    className="!bg-white"
                    placeholder=" "
                    maxLength={"20"}
                    required={true}
                    value={address.values.city}
                    onChange={address.handleChange}
                    onBlur={address.handleBlur}
                    error={address.touched.city && address.errors.city}
                  />
                  {address.touched.city && address.errors.city && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {address.errors.city}
                    </div>
                  )}
                </div>
                <div className="col-span-4">
                  <Select
                    label="State"
                    name="state"
                    placeholder=""
                    className="!bg-white"
                    required={true}
                    onChange={handleSelectChange1}
                    options={state}
                    value={address.values.state}
                    onBlur={address.handleBlur}
                    error={address.touched.state && address.errors.state}
                  />
                  {address.touched.state && address.errors.state && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {address.errors.state}
                    </div>
                  )}
                </div>
                <div className="col-span-4">
                  <Input
                    type="number"
                    name="zip"
                    label="Zipcode"
                    className="!bg-white"
                    placeholder=""
                    required={true}
                    zipcode={true}
                    value={address.values.zip}
                    onChange={address.handleChange}
                    onBlur={address.handleBlur}
                    minLength={"5"}
                    maxLength={"6"}
                    error={address.touched.zip && address.errors.zip}
                  />
                  {address.touched.zip && address.errors.zip && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {address.errors.zip}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid className="drop-shadow-5xl px-8 mt-8">
                <div className="col-span-4">
                  <InActiveButton
                    type="button"
                    className="border w-full !text-sm !font-Regular"
                    onClick={closeUserModal1}
                  >
                    Cancel
                  </InActiveButton>
                </div>
                <div className="col-span-8">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
}
export default CustomerDetails;
